<template lang='pug'>
	.settings
		div(v-if='item')
			project-alerts
			h2.settings__title {{ $t('h1.settings') }}

			b-tabs(nav-class='tabs-filled tabs-filled-transparent' v-model='tab' no-fade)
				b-tab(:title="$t('project.mainShort')")
					card-settings
				b-tab(v-if="!item.isNeuro()" :title="$t('project.decoration')")
					.d-flex.flex-column.flex-md-row
						.d-flex.flex-column.mb-3.mb-md-0.mr-md-3.card-basic.settings__card
							form-add-project(@success='' @disabledSaveButton='($e) => disabledSaveButton = $e')
							.btn.btn-primary.w-100.mt-4(@click='saveProject()' :class="{'disabled': isPendingProject || disabledSaveButton}") {{$t('forms.save')}}
						.d-flex.flex-column.settings__card(v-if='item.isActive() && me && me.hasFilledAccount() && me.hasFilledPaymentAccounts() && item.canSeeCover() && item.somePlansHasResourceActive()')
							payment-link.mb-3(:edit="false" :projectImage="item.image.contentUrl" :projectName="item.title")
							.mb-3.card-basic
								.d-flex.justify-content-between.align-items-center
									.d-flex.flex-column
										p.b1.mb-2 {{ $t('project.paymentPageStyle') }}
										span.b3.text-grey {{ pageColor ? $t('project.dark') : $t('project.light') }}
									switches#tool-mode-switch.set-theme-switch(v-model="pageColor")
							.disclaimer
								p.mb-0(v-html="$t('forms.coverDescription')")
				b-tab(v-if="!item.isNeuro()" :title="$t('project.analytics.postback')")
					webhooks.mb-4(v-if='item')
				b-tab(v-if="!item.isNeuro()" :title="$t('project.analytics.through')")
					integration.mb-4
		.loading(v-else)
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CardSettings from '@/components/Cards/Settings';
import Webhooks from '@/components/Form/Webhooks';
import AddProject from '@/components/Form/AddProject';
import Integration from '@/components/Common/Integration';
import Info from '@/components/Cards/Info';
import { bus } from '@/main.js';
import Switches from "vue-switches";
import PaymentLink from "@/components/Common/PaymentLink";
import ProjectAlerts from '@/components/Cards/ProjectAlerts'

export default {
	name: 'ProjectSettings',
	props: ['id'],
	components: {
		CardSettings,
		Webhooks,
		Info,
		Integration,
		'form-add-project': AddProject,
		Switches,
		ProjectAlerts,
		PaymentLink
	},
	metaInfo() { return {title: this.getTitle}},
	data: () => ({
		tab: 0,
		disabledSaveButton: true,
		canBeDeleted: false,
		pageColor: false
	}),
	created() {
		this.getShortList();
		if (this.item && this.item.id)
			this.getCanBeDeleted({id: this.item.id})

		if ( this.$route.query && this.$route.query.tab ) {
			this.tab = Number(this.$route.query.tab);
		}
		this.pageColor 	= this.item && this.item.isPaymentPageDark ? this.item.isPaymentPageDark : false;
	},
	computed: {
		...mapGetters({
			item: 'project/opened',
			isPendingProject: 'project/isPending',
			isCanBeDeleted: 'project/canBeDeleted'
		}),
		getTitle () {
			if ( this.item ) {
				return this.item.title;
			}
			return 'Проект';
		}
	},
	methods: {
		...mapActions({
			edit: 'project/edit',
			getShortList: 'project/shortList',
			getCanBeDeleted: 'project/canBeDeleted'
		}),
		async saveProject () {
			let r = {response: true};
			await bus.$emit('validateForm', r);

			if ( r.response ) {
				new Promise( async (res, rej) => {
					bus.$emit('sendForm', {res, rej});
				})
				.then ( () => this.$notify("success", `😊&nbsp;&nbsp;&nbsp;${this.$t('success.projectEdit')}`))
				.catch (v => this.$notify("error", `😢&nbsp;&nbsp;&nbsp;${v}`));
			}
		},
	},
	watch : {
		pageColor ( nv ) {
			if ( this.item && nv != this.item.isPaymentPageDark ) {
				let canBeDeleted = this.isCanBeDeleted;

				this.edit({id: this.item.id, isPaymentPageDark: nv}).then( v => {
					this.item.isPaymentPageDark = v.isPaymentPageDark;
					this.item.canBeDeleted = canBeDeleted;
				});
			}
		},
		item() {
			this.pageColor 	= this.item ? this.item.isPaymentPageDark : null;
		},
		tab() {
			this.disabledSaveButton = true;
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.settings {
	&__title {
		margin-bottom: 14px !important;

		@include respond-below(sm) {
			margin-bottom: 16px !important;
		}
	}

	&__card {
		width: 50%;

		@include respond-below(sm) {
			width: 100%;
		}
	}

	.nav-tabs {
		@include respond-below(md) {
			padding: 0 12px 20px;
			margin: 0 -12px;
		}
	}
}
</style>
