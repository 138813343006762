<template lang='pug'>
	.d-flex.flex-wrap.justify-content-between.integration
		//- GOOGLE
		.d-flex.justify-content-between.align-items-center.cursor-pointer.mb-3.integration__card(
			:class="{'success': hasGAPixel && isGAConnected, 'error': hasGAPixel && !isGAConnected}"
			@click="$bvModal.show('integration-form-ga')"
		)

			span.b4.integration__text(v-if='hasGAPixel' :class="isGAConnected ? 'text-success' : 'text-error'") {{ isGAConnected ? $t('forms.connected') : $t('errors.metricId') }}
			img(src="/assets/img/icons/ga.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="item && !item.getGAId()")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-ga'" :icon="isMobile ? '' : '/assets/img/icons/ga.svg'" :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : '/assets/img/icons/ga.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.gaCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-if="error" v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="ga.gaId"
					:label="$t('pixel.counter_id')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorGA"
					@blur='isGAValid()'
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;Google Analytics`" :href="'https://docs.paywall.pw/rus/obshee/integracii/google-analytics'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !ga.gaId || errorGA}" @click='saveGA')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getGAId()" @click='openModalRemove("GA")')

		//- FACEBOOK
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			@click="$bvModal.show('integration-form-facebook')"
			:class="{'success': hasFBPixel && isFBConnected, 'error': hasFBPixel && !isFBConnected}"
		)
			span.b4.integration__text(v-if='hasFBPixel' :class="isFBConnected ? 'text-success' : 'text-error'") {{ isFBConnected ? $t('forms.connected') : $t('errors.pixelId') }}
			img(:src="isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'")
			span.btn.btn-primary.integration__plus-icon(v-if="!hasFBPixel")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-facebook'" :icon="isMobile ? '' : isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'"  :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : isDarkColor ? '/assets/img/icons/fb-dark.svg' : '/assets/img/icons/fb.svg'")
				p.b3.mb-3(v-if="error" v-html="$t('forms.fbCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="pixel.pixelId"
					:label="'Pixel ID'"
					:icon="'icon-archive-book'"
					:errorFeedback="errorFB"
					@blur='isFBValid()'
				)
				p.b1.mb-3 {{ $t('pixel.eventValue') }}
				p.b3.text-grey.mb-4 {{ $t('pixel.assignValue') }}
				my-input.mb-4(
					v-model.trim="pixel.viewProject"
					:label="`view_project (${$t('pixel.view_project')})`"
					:icon="'icon-home-hashtag'"
					:errorFeedback="''"
				)
				my-input.mb-4(
					v-model.trim="pixel.clickPurchase"
					:label="`click_purchase (${$t('pixel.click_purchase')})`"
					:icon="'icon-wallet'"
					:errorFeedback="''"
				)
				my-input.mb-4(
					v-model.trim="pixel.purchase"
					:label="`purchase (${$t('pixel.purchase')})`"
					:icon="'icon-card-tick'"
					:errorFeedback="''"
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;Facebook`" :href="'https://docs.paywall.pw/rus/obshee/integracii/facebook-pixel'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !pixel.pixelId || errorFB}" @click='saveFB' :disabled='isPending')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="hasFBPixel" @click='openModalRemove("FB")')

		//- YANDEX
		.d-flex.justify-content-between.cursor-pointer.align-items-center.mb-3.integration__card(
			:class="{'success': hasYAPixel && isYAConnected, 'error': hasYAPixel && !isYAConnected}"
			@click="$bvModal.show('integration-form-ya')"
		)
			span.b4.integration__text(v-if='hasYAPixel' :class="isYAConnected ? 'text-success' : 'text-error'") {{ isYAConnected ? $t('forms.connected') : $t('errors.metricId') }}
			img(:src="isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'")
			span.btn.btn-primary.integration__plus-icon(v-if="!ya.ymId")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-ya'" :icon="isMobile ? '' : isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'"  :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : isDarkColor ? '/assets/img/icons/ya-dark.svg' : '/assets/img/icons/ya.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.yaCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-if="error" v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="ya.ymId"
					:label="$t('pixel.counter_id')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorYA"
					@blur='isYAValid'
				)
				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;${$t('tutorials.yandexMetrica')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/yandeks-metrika'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !ya.ymId || errorYA}" @click='saveYA')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getYMId()" @click='openModalRemove("YA")')

		//- VCONTACTE
		.d-flex.justify-content-between.cursor-pointer.align-items-center.integration__card(
			:class="{'success': hasVKPixel && isVKConnected, 'error': hasVKPixel && !isVKConnected}"
			@click="$bvModal.show('integration-form-vk')"
		)
			span.b4.integration__text(v-if='hasVKPixel' :class="isVKConnected ? 'text-success' : 'text-error'") {{ isVKConnected? $t('forms.connected') : $t('errors.pixelId') }}
			img(src="/assets/img/icons/vk-pixel.svg")
			span.btn.btn-primary.integration__plus-icon(v-if="!vkPixel.id")
				span.m-0.plus-primary
			.d-flex.justify-content-between.align-items-center.integration__edit-icon(v-else)
				i.fontello.fontello-icon-edit

			modal-general(:id="'integration-form-vk'" :icon="isMobile ? '' : '/assets/img/icons/vk-pixel.svg'"  :title="isMobile ? $t('h1.settings') : ''")
				img.mb-3.mb-md-0.mr-auto.integration__modal-image(:src="!isMobile ? '' : '/assets/img/icons/vk-pixel.svg'")
				p.b3.mb-3(v-if="!error" v-html="$t('forms.vkPixelCounter')")
				//- p.b3.mb-4.position-relative.integration__error(v-if="error" v-html="'Описание ошибки'")
				my-input.mb-4(
					v-model.trim="vkPixel.id"
					prefix=''
					:label="$t('pixel.vkCodeTitle')"
					:icon="'icon-archive-book'"
					:errorFeedback="errorVK"
					@blur='isVKValid()'
				)
				p.b3.mb-4.position-relative.integration__warning(v-html="$t('pixel.vkWarning')")

				.d-flex.justify-content-between.mb-4.integration__card-link
					.d-flex.flex-column
						p.b2.text-grey.mb-1 {{ $t('h1.addressPaymentPage') }}
						a.b1.text-break.text-primary(:href='paymentLinkDefault' target='_blank') {{ paymentLinkDefault }}
					i.text-primary.fontello.fontello-icon-copy.cursor-pointer.mb-auto.mt-1.mt-md-auto.mb-md-1(@click="copy(paymentLinkDefault)" v-clipboard:copy="paymentLinkDefault")

				p.b4.text-grey.mb-2(v-html="$t('forms.learnMoreKnowledgeBase')")
				pw-btn-link.mb-4.mb-md-1.integration__base-link(:text="`🤝&nbsp;${$t('tutorials.VKpixel')}`" :href="'https://docs.paywall.pw/rus/obshee/integracii/vk-piksel'" :icon="'no-icon'" :blank='true')
				.d-flex.w-100.mt-auto.mt-md-4
					.btn.btn-primary.w-100(:class="{'disabled': !vkPixel.id || errorVK}" @click='saveVK')
						.loader(v-if='isPending')
						span(v-else) {{ $t('forms.save') }}
					i.fontello-btn.fontello-btn-red.fontello-icon-trash.ml-2(v-if="item && item.getVKPixelId()" @click='openModalRemove("VK")')
		modal-remove-integration(
			:id="`remove-integration`"
			:title="$t('forms.removeIntegration')"
			:text="$t('forms.removeIntegrationDesc')"
			:item='removeItem'
			@onAction='actionRemove')
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import ModalRemoveIntegration from '@/components/Modals/RemoveIntegration';
import MyInput from "@/components/UI/MyInput";
import PwBtnLink from '@/components/Buttons/PwBtnLink';
import { Project } from '@/models/project';

export default {
	name: 'Integration',
	components: {
		ModalGeneral,
		ModalRemoveIntegration,
		MyInput,
		PwBtnLink
	},
	data: () => ({
		ga: {gaId: null},
		pixel: {
			pixelId: null,
			viewProject: null,
			clickPurchase: null,
			purchase: null
		},
		ya: {ymId: null},
		vkPixel: {id: null},
		hasFBPixel: false,
		editIntegration: false,
		removeItem: null,
		success: false,
		error: false,
		errorVK: '',
		errorYA: '',
		errorGA: '',
		errorFB: ''
	}),
	created () {
		if ( this.item != null ) {
			this.setForm(this.item);
		}

		this.$on('removeYA', this.removeYA);
	},
	computed: {
		...mapGetters({
			item 			: 'project/opened',
			isPending 		: 'project/isPending',
		}),
		paymentLink () {
			return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
		},
		paymentLinkDefault() {
			if ( this.link != null ) return this.link;
			if ( !this.item ) return '';

			if ( this.item.hashIdCustom != this.item.hashId ) return this.paymentLink;

			return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
		},
		paymentLinkDomain() {
			return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
		},
		hasGAPixel () { return this.item && this.item.getGAId() },
		isGAConnected () {
			if ( !this.item ) return false;
			return Project.isGAValid(this.item.getGAId());
		},
		isFBConnected () {
			if ( !this.item ) return false;
			return Project.isFBValid(this.item.getPixelId());
		},
		hasVKPixel () { return this.item && this.item.getVKPixelId() },
		isVKConnected () {
			if ( !this.item ) return false;
			return Project.isVKPixelValid(this.item.getVKPixelId());
		},
		hasYAPixel () { return this.item && this.item.getYMId() },
		isYAConnected () {
			if ( !this.item ) return false;
			return Project.isYAValid(this.item.getYMId());
		}
	},
	mounted() {
		this.$root.$on('bv::modal::hide', () => {
			if (this.errorVK) {
				this.errorVK = '';
				this.vkPixel.id = null;
			}

			if (this.errorYA) {
				this.errorYA = '';
				this.ya.ymId = null;
			}

			if (this.errorGA) {
				this.errorGA = '';
				this.ga.gaId = null;
			}

			if (this.errorFB) {
				this.errorFB = '';
				this.clearFBPixel();
			}

			this.setForm(this.item);
		});
	},
	methods: {
		...mapActions({
			edit		: 'project/edit',
			checkFB		: 'project/testFBPixel',
			checkYA		: 'project/testYAMetric'
		}),
		actionRemove() {
			if ( !this.removeItem ) return;

			if ( this.removeItem.type == 'YA' ) {
				this.ya.ymId = null;
				this.saveYA();
			}
			if ( this.removeItem.type == 'GA' ) {
				this.ga.gaId = null
				this.saveGA();
			}
			if ( this.removeItem.type == 'VK' ) {
				this.vkPixel.id = null
				this.saveVK();
			}
			if ( this.removeItem.type == 'FB' ) {
				this.clearFBPixel();
				this.saveFB();
			}

			this.$bvModal.hide('remove-integration');
		},
		saveVK() {
			_.each(this.vkPixel, (p,i,l) => l[i] = p == '' ? null : (p == null ? p : p.trim()) );

			this.edit({vkPixel: this.vkPixel.id, id: this.item.id})
				.then( v => {
					this.$store.commit('project/editAnalytics', {vkPixel: this.vkPixel.id});
					this.$notify("success", this.$t(this.vkPixel.id == null ? 'success.removeIntegration' : 'success.addedInegration'))
					this.$bvModal.hide('integration-form-vk');
				})
				.catch( er => this.$notify("error", this.$t('project.analytics.titleEdit'), er) );
		},
		saveGA() {
			_.each(this.ga, (p,i,l) => l[i] = p == '' ? null : (p == null ? p : p.trim()) );

			this.edit({googleAnalytics: this.ga, id: this.item.id})
				.then( v => {
					this.$store.commit('project/editAnalytics', {ga: _.cloneDeep(this.ga)});
					this.$notify("success", this.$t(this.ga.gaId == null ? 'success.removeIntegration' : 'success.addedInegration'))
					this.$bvModal.hide('integration-form-ga');
				})
				.catch( er => this.$notify("error", this.$t('project.analytics.titleEdit'), er) );
		},
		saveYA() {
			_.each(this.ya, (p,i,l) => l[i] = p == '' ? null : (p == null ? p : p.trim()) );

			this.edit({yandexMetrics: this.ya, id: this.item.id})
				.then( v => {
					this.$store.commit('project/editAnalytics', {ya: _.cloneDeep(this.ya)});
					this.$notify("success", this.$t(this.ya.ymId == null ? 'success.removeIntegration' : 'success.addedInegration'))
					this.$bvModal.hide('integration-form-ya');
				})
				.catch( er => this.$notify("error", this.$t('project.analytics.titleEdit'), er) );
		},
		saveFB() {
			_.each(this.pixel, (p,i,l) => l[i] = p == '' ? null : (p == null ? p : p.trim()) );

			this.edit({facebookPixel: this.pixel, id: this.item.id})
				.then( v => {
					this.$store.commit('project/editAnalytics', {pixel: _.cloneDeep(this.pixel)});
					this.$notify("success", this.$t(this.pixel.pixelId == null ? 'success.removeIntegration' : 'success.addedInegration'))
					this.$bvModal.hide('integration-form-facebook');
				})
				.catch( er => this.$notify("error", this.$t('project.analytics.titleEdit'), er) );
		},
		setForm(nv) {
			this.pixel.pixelId 			= nv.pixel.getId();
			this.pixel.viewProject 		= nv.pixel.getViewProject();
			this.pixel.clickPurchase 	= nv.pixel.getClickPurchase();
			this.pixel.purchase 		= nv.pixel.getPurchase();
			this.ga.gaId 				= nv.getGAId();
			this.ya.ymId 				= nv.getYMId();
			this.vkPixel.id 			= nv.getVKPixelId();
			this.hasFBPixel 			= this.pixel && this.pixel.pixelId;
		},

		clearFBPixel() {
			this.pixel.pixelId 			= null;
			this.pixel.viewProject 		= null;
			this.pixel.clickPurchase 	= null;
			this.pixel.purchase 		= null;
			this.hasFBPixel 			= false;
		},
		isVKValid() {
			this.errorVK = Project.isVKPixelValid(this.vkPixel.id) ? '' : this.$t('errors.VKpixelId');
		},
		isGAValid() {
			this.errorGA = Project.isGAValid(this.ga.gaId) ? '' : this.$t('errors.GAMetricId');
		},
		isFBValid() {
			this.errorFB = Project.isFBValid(this.pixel.pixelId) ? '' : this.$t('errors.pixelId');
		},
		isYAValid() {
			this.errorYA = Project.isYAValid(this.ya.ymId) ? '' : this.$t('errors.metricId');
		},
		async copy(text) {
			try {
				await this.$copyText(text);
				this.$notify("success", `😊 &nbsp;&nbsp;&nbsp;${this.$t('success.copyLink')}`)
			} catch (e) {
			}
		},

		openModalRemove ( type, item ) {
			this.removeItem = _.cloneDeep({type: type});
		},
	},
	watch: {
		item(nv) {
			if(nv != null) {
				this.setForm(nv);
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.integration {
	&__card {
		width: 49%;
		height: 100px;
		padding: 27px 45px;
		border-radius: var(--border-radius-rounded);
		box-shadow: var(--box-shadow-dark-none);
		background-color: var(--foreground-color);
		transition: var(--animation-time-short);
		overflow: hidden;

		&:hover,
		&:active {
			box-shadow: var(--box-shadow-hover);
		}

		@include respond-below(sm) {
			width: 100%;
		}

		&.success {
			position: relative;
			order: -1;
			padding: 38px 45px 27px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 24px;
				background-color: var(--brand-bg-success);
			}
		}

		&.error {
			position: relative;
			padding: 38px 45px 27px;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 24px;
				background-color: var(--brand-bg-error);
			}
		}
	}

	&__text {
		position: absolute;
		top: 3px;
		left: 50%;
		transform: translateX(-50%);
	}

	&__plus-icon {
		width: 35px;
		max-width: 35px;
		min-width: 35px;
		height: 35px;
		max-height: 35px;
		min-width: 35px;
		padding: 8px;
		box-shadow: none !important;

		&:hover,
		&:active {
			background-color: var(--brand-primary) !important;
			box-shadow: none !important;
		}
	}

	&__edit-icon {
		width: 35px;
		max-width: 35px;
		min-width: 35px;
		height: 35px;
		max-height: 35px;
		min-width: 35px;
		padding: 8px;
		border-radius: var(--border-radius-rounded);
		background-color: var(--primary-bg-color);

		i {
			font-size: 16px !important;
			color: var(--brand-primary);
		}
	}

	&__base-link {
		max-width: 228px;
	}

	&__warning {
		padding: 16px 16px 16px 51px;
		background-color: var(--brand-bg-warning);
		border-radius: var(--border-radius-rounded);

		&::before {
			content: '';
			position: absolute;
			top: 16px;
			left: 16px;
			width: 24px;
			height: 24px;
			background: url('/assets/img/icons/info-square.svg');
			background-repeat: no-repeat;
		}

		@include respond-below(sm) {
			padding: 16px 21px 16px 51px;
		}
	}

	&__error {
		padding: 16px 16px 16px 51px;
		background-color: var(--brand-bg-error);
		border-radius: var(--border-radius-rounded);

		&::before {
			content: '';
			position: absolute;
			top: 16px;
			left: 16px;
			width: 24px;
			height: 24px;
			mask-image: url('/assets/img/icons/info-square.svg');
			background-color: var(--brand-error);
			background-repeat: no-repeat;
		}

		@include respond-below(sm) {
			padding: 16px 21px 16px 51px;
		}
	}

	&__card-link {
		padding: 15px;
		border-radius: var(--border-radius-rounded);
		background-color: var(--grey-bg-color);
	}

	&__modal-image {
		@include respond-below(sm) {
			max-width: 172px;
			max-height:27px;
		}
	}
}
</style>
