<template lang='pug'>
	b-form.add-project(data-vv-scope='project', autocomplete='off')
		.mb-3
			my-input(
				v-model="$v.form.title.$model"
				:label="$t('forms.name')"
				:isError="isFormSendAttempt && !$v.form.title.required || isFormSendAttempt && !$v.form.title.minLength || isFormSendAttempt && !$v.form.title.maxLength || form.title.length < 1"
			)
			.text-danger.b4.mt-1(v-if='isFormSendAttempt && !$v.form.title.required || form.title.length < 1') {{ $t('errors.required.title' ) }}
			.text-danger.b4.mt-1(v-else-if='isFormSendAttempt && !$v.form.title.minLength') {{ $t('errors.minLength.title' )}}
			.text-danger.b4.mt-1(v-else-if='isFormSendAttempt && !$v.form.title.maxLength') {{ $t('errors.maxLength.title' )}}

		.mb-3
			my-text-area(v-model="$v.form.description.$model"
				:label="$t('forms.placeholder.description')"
				:isError="isFormSendAttempt && !$v.form.description.required || isFormSendAttempt && !$v.form.description.maxLength || isFormSendAttempt && !$v.form.description.minLength || form.description.length < 1"
			)
			.text-danger.b4.mt-1(v-if='isFormSendAttempt && !$v.form.description.required || form.description.length < 1') {{ $t('errors.required.description' )}}
			.text-danger.b4.mt-1(v-else-if='isFormSendAttempt && !$v.form.description.minLength') {{ $t('errors.minLength.description' )}}
			.text-danger.b4.mt-1(v-else-if='isFormSendAttempt && !$v.form.description.maxLength') {{ $t('errors.maxLength.description' )}}


		.position-relative.cursor-pointer.add-project__image(v-if="form.imageUrl")
			img.w-100(:src="form.imageUrl")
			i.fontello-btn.fontello-btn-primary.fontello-icon-edit.add-project__icon.add-project__icon--edit(v-if="!isMobile" @click="editImage()")
			.d-flex.align-items-center.mt-3.mt-md-0
				.btn.btn-border.mr-3.add-project__button(v-if="isMobile" @click="editImage()") {{$t('forms.edit')}}
				i.fontello-btn.fontello-btn-red.fontello-icon-trash.add-project__icon.add-project__icon--trash(@click="removeImage()")

		//- Drop zone for upload
		vue-dropzone.d-flex.justify-content-center.align-items-center(
			:key="udateDropzone"
			id="createDropzone"
			v-show='!form.image && cover.img == null || !form.imageUrl'
			:options='dropzoneOptions'
			ref="dropzoneInstance"
			v-on:vdropzone-success='addImage'
			v-on:vdropzone-file-added='selectedCover'
			:class='{"is-invalid" : isFormSendAttempt && $v.form.image.$error || form.img == null, "d-none-important" : !(!form.image && cover.img == null || !form.imageUrl)}')
		//-.text-danger.b4.mt-1(v-if='isFormSendAttempt && cover.img == null && !$v.form.image.imageR || !$v.form.image.imageR && cover.img == null') {{ $t('errors.required.cover' )}}

		//-  Cropper ZONE after upload image
		modal-general(:id="'cropper-zone'" :title="$t('project.cropEdit')" :contentClass="'cropper-zone'" :no-close-on-backdrop='true')
			p.b1.mb-4.text-grey(v-if='cover.img != null') {{ $t('project.cropDesc') }}

			cropper.cropper(
				v-if='cover.img != null'
				:id='`cropper`'
				ref="cropper"
				:src="cover.img"
				:stencilProps='{aspectRatio: 16/9}')

			.d-flex.align-items-center.my-4(v-if="isMobile" @click="$refs.dropzoneInstance.$el.click()")
				<svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 17v-6l-2 2M9 11l2 2"/><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5"/><path stroke="#52A7F9" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M18 10c-3 0-4-1-4-4V2l8 8h-4Z"/></svg>
				span.b3.text-primary {{ $t('wizard.uploadPhoto') }}

			.d-flex.flex-column-reverse.flex-md-row-reverse.justify-content-end.mt-1.mt-md-4(v-if='cover.img != null')
				.btn.btn-border.mr-md-auto.add-project__button(@click="cancelCroppingCover()") {{$t('forms.cancel2')}}
				.btn.btn-primary.mb-3.mb-md-0.mr-md-3.add-project__button(@click="uploadCover()" :disabled='isPending')
					.loader(v-if="isloading")
					span(v-else) {{ $t('forms.install') }}
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { Cropper } from 'vue-advanced-cropper';
import { mapGetters } from 'vuex';
import { bus } from '@/main.js';
import { mapActions } from 'vuex';
import { DROPZONE_OPTIONS } from '@/constants/config';
import MyInput from "@/components/UI/MyInput";
import MyTextArea from "@/components/UI/MyTextArea";
import ModalGeneral from '@/components/Modals/ModalGeneral';
import heic2any from 'heic2any';

const { required, minLength, maxLength } = require("vuelidate/lib/validators");

var dataURLtoBlob = require('blueimp-canvas-to-blob');

export default {
	name: 'AddProjectForm',
	components: {
		vueDropzone: vue2Dropzone,
		Cropper,
		MyInput,
		MyTextArea,
		ModalGeneral
	},
	mounted () {
		bus.$on('validateForm', this.validateForm);
		bus.$on('sendForm', this.sendForm);
		this.$root.$on('bv::modal::hide', () => {
			this.cover.img = null;
			this.udateDropzone++;
		})
	},
	destroyed () {
		bus.$off('validateForm', this.validateForm);
		bus.$off('sendForm', this.sendForm);
	},
	created () {
		if ( this.item != null ) {
			this.setItem(this.item);
		}
	},
	data: () => ({
		udateDropzone: 0,
		errorTitle: "",
		fileType: 'image/png',
		fileExt: 'png',
		progressValue: 0,
		cover: {
			img 		: null,
		},
		form : {
			title		: null,
			description : null,
			image		: null,
			imageUrl	: null
		},
		isFormSendAttempt : false,
		isloading: false,
		removeBtnPressed: false
	}),
	validations: {
		form: {
			title: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(50)
			},
			description: {
				required,
				minLength: minLength(3),
				maxLength: maxLength(5000)
			},
			image : {
				imageR : (value) => {
					return value != null && value.id;
				}
			}
		}
	},
	methods: {
		...mapActions({
			add		: 'project/add',
			edit	: 'project/edit'
		}),
		async validateForm (a) {
			this.isFormSendAttempt = true;
			this.$v.$touch();

			if( this.$v.form.$anyError ) a.response = false;

			return a.response;
		},
		async sendForm ({res, rej}) {
			if ( this.form.id && this.form.id != null ) {
				await this.edit({title: this.form.title, description: this.form.description, id: this.form.id, image: this.form.image.id})
					.then( (i) => {
						this.form.id = i.id;
						res();
					})
					.catch( (v) => {
						rej(v);
					});
				return;
			}

			await this.add({title: this.form.title, description: this.form.description, image: this.form.image.id})
				.then( i => {
					this.form.id = i.id;
					res();
				})
				.catch( v => {
					rej(v);
				})
		},

		async editImage() {
			if ( !this.cover.img ) {
				this.cover.img = this.form.imageUrl;
			}

			this.$bvModal.show('cropper-zone');
		},
		removeImage () {
			this.form.image = null;
			this.form.imageUrl = null;
			this.removeBtnPressed = true;
			this.cover.img = null;
			this.udateDropzone++;
		},
		addImage (file, response) {
			this.form.image = response;
		},
		errorDropzone (file, er) {
			this.$notify("error", "Error", er);
		},
		async selectedCover ( file ) {
			let img = null;
			let type = file.type;

			/* Перевёл в МБ */
			if((file.size / 1024) / 1024 > 5) {
				this.$notify("error", this.$t('errors.imgBigError'));
				this.isloading = false;
				this.udateDropzone++;
				return false;
			}

			if (file && (file.type.match('heic') || file.name.match('heic'))) {
				img = await heic2any({blob: file, toType: "image/jpeg", quality: 0.2});
				type = "image/jpeg";
			}

			var reader = new FileReader()

			reader.onload = (e) => {
				let data;

				if (typeof e.target.result === 'object') {
					data = window.URL.createObjectURL(img == null ? new Blob([e.target.result]) : img)
					this.$bvModal.show('cropper-zone');
				} else {
					data = e.target.result
				}
				this.cover.img = data
			}

			this.fileType = type;
			this.fileExt  = _.last(type.split('/'));

			reader.readAsArrayBuffer(file)
		},
		getFixedUploadCover () {
			let isBigger = false;
			var img = this.$refs.cropper.getResult();
			var imgFixed;

			if ( img.coordinates.width > img.canvas.width ) {
				img.coordinates.width = img.canvas.width;
				isBigger = true;
			}

			if ( img.coordinates.height > img.canvas.height ) {
				img.coordinates.height = img.canvas.height;
				isBigger = true;
			}

			if ( isBigger ) {
				this.$refs.cropper.setCoordinates(img.coordinates);
				imgFixed = this.$refs.cropper.getResult();
			}

			return img.canvas.toDataURL(this.fileType);
		},
		uploadCover () {
			this.isloading = true;
			if ( this.isPending ) return false;
			this.activeProgressCover();

			this.$store.dispatch("users/putImage", {data: {file: new File([dataURLtoBlob(this.getFixedUploadCover())], "cover." + this.fileExt)}, uploadCallback: this.progress})
				.then( i => {
					this.form.image 	= i;
					this.form.imageUrl 	= i.contentUrl;
					this.activeProgressCover(false);
					//this.saveProject();
					this.$notify("success", this.$t('success.projectEdit'));
					this.$bvModal.hide('cropper-zone');
					this.isloading = false;
				})
				.catch( v => {
					let text = v;

					if ( v == 'ERR_NETWORK' ) text = this.$t('errors.imageTooBig');

					this.$notify("error", `😊 &nbsp;&nbsp;&nbsp;${text}`);
					this.isloading = false;
				});
		},
		async saveProject () {
			let r = {response: true};
			await this.validateForm(r);

			if ( r.response ) {
				new Promise( async (res, rej) => {
					this.sendForm({res, rej});
				})
				.then ( () => this.$notify("success", this.$t('success.projectEdit')))
				.catch (v => this.$notify("error", `😊 &nbsp;&nbsp;&nbsp;${v}`));
			}
		},
		progress (progress) {
			this.progressValue = progress.loaded / progress.total * 100;
		},
		activeProgressCover ( state = true ) {
			if ( !state ) {
				this.progressValue = 0;
				return;
			}

			this.progressValue = 1;
		},
		cancelCroppingCover() {
			this.$bvModal.hide('cropper-zone');
		},
		setItem (nv) {
			this.form.title 		= nv.title;
			this.form.image 		= nv.image;
			this.form.imageUrl 		= nv.image != null && nv.image.contentUrl ? nv.image.contentUrl : null;
			this.form.description	= nv.description;
			this.form.id 			= nv.id;
		},
	},
	computed: {
		...mapGetters({
			item 		: 'project/opened',
			isPending 	: 'users/isPending',
		}),
		rowsDesc () {
			return this.isMobile ? 9 : 6;
		},
		dropzoneOptions () {
			return DROPZONE_OPTIONS.apply(this);
		}
	},
	watch: {
		item(nv) {
			if (nv != null) {
				this.setItem(nv);
			}
		},
		form: {
			handler(nv) {
				if(nv.title == null || nv.description == null || nv.title == '' || nv.description == '' || nv.image == null) {
					this.$emit('disabledSaveButton', true);
				} else {
					this.$emit('disabledSaveButton', false);
				}

				if (nv.title == this.item.title && nv.description == this.item.description && nv.image == this.item.image)
					this.$emit('disabledSaveButton', true);
			},
			deep: true
		}
	}
}
</script>

<style lang='scss' scoped>
	.d-none-important {
		display: none !important;
	}
</style>
<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.add-project {
	&__image {
		border-radius: var(--border-radius-rounded);
		overflow: hidden;

		img {
			min-height: 260px;
			object-fit: cover;

			@include respond-below(sm) {
				min-height: 198px;
				border-radius: var(--border-radius-rounded);
			}
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: transparent;
			transition: var(--animation-time-short);
		}

		&:hover {
			&::before {
				background-color: rgba(#000, 0.6);
			}

			.add-project__icon {
				visibility: visible;
			}
		}

		@include respond-below(sm) {
			&:hover {
				&::before {
					content: none;
				}
			}
		}
	}

	&__icon {
		visibility: hidden;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: 100ms;

		&--edit {
			left: 50%;
			transform: translateX(calc(-100% - 7.5px));

			@include respond-below(sm) {
				transform: translateX(0);
			}
		}

		&--trash {
			right: 50%;
			transform: translateX(calc(100% + 7.5px));

			@include respond-below(sm) {
				position: relative;
				right: 0;
				top: 0;
				visibility: visible;
				transform: translate(0);
			}
		}
	}

	&__button {
		width: 185px;

		@include respond-below(sm) {
			width: 100%;
			min-height: 50px;
			height: 50px;
		}
	}
}
</style>
