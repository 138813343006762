import { render, staticRenderFns } from "./Integration.vue?vue&type=template&id=85bc4d66&scoped=true&lang=pug"
import script from "./Integration.vue?vue&type=script&lang=js"
export * from "./Integration.vue?vue&type=script&lang=js"
import style0 from "./Integration.vue?vue&type=style&index=0&id=85bc4d66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85bc4d66",
  null
  
)

export default component.exports