<template lang='pug'>
	b-modal#modalRemove(ref='modal' centered content-class="remove-project" header-class="p-0 border-0 align-items-center" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
		template(#modal-header="{ close }")
			span.b1 {{ item && isCanBeDeleted ? $t('project.removeProject') : $t('project.archiveProject') }}
			icon-close(@click="close()")

		p.b3.m-0.overflow-wrap(v-if='item && isCanBeDeleted' v-html="`${$t('h1.project')} <span class='text-bold'>${item.title ? item.title : $t('instruction.step0.neurobot.newNeurobot')}</span> ${$t('plan.removePlanConfirm')}`")
		span.b3.mb-4(v-if='item && isCanBeDeleted') {{ $t('plan.confirmAction') }}
		p.b3.m-0.mb-4(v-else) <span class="text-bold">{{ item ? item.title : '' }}</span> {{ item && item.isNeuro() ? $t('plan.archiveProjectNeurobot') : $t('plan.archivePlanConfirmEnd') }}

		.d-flex.justify-content-between
			.btn.btn-border.btn-small.w-100.remove-project__btn-cancel(@click="close") {{ $t('forms.cancel2') }}
			.btn.btn-peril.btn-small.w-100(v-if='item && isCanBeDeleted' @click='removeProject' :class="{'disabled': isPending || isLoading}")
				.loader(v-if='isLoading')
				span(v-else) {{ $t('forms.remove') }}
			.btn.btn-peril.btn-small.w-100(v-else-if='item && !isCanBeDeleted' @click='archiveProject' :class="{'disabled': isPending || isLoading}")
				.loader(v-if='isLoading')
				span(v-else) {{ $t('forms.move') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'ModalRemoveProject',
	components: {
		IconClose
	},
	props: {
		item: {
			type: Object,
			default: () => (null)
		}
	},
	data: () => ({
		isLoading: false
	}),
	computed: {
		...mapGetters({
			isPending: 'project/isPending',
			projects: 'project/items',
			projectsShorts : 'project/itemsShort',
			isCanBeDeleted: 'project/canBeDeleted'
		})
	},
	methods: {
		...mapActions({
			remove: 'project/remove',
			archive: 'project/archive',
			getShortList: 'project/shortList'
		}),
		removeProject () {
			this.isLoading = true;

			this.remove({id: this.item.id})
				.then(() => {
					this.$notify("success", this.$t('success.projectRemove'));
					this.$store.commit('project/remove', this.item);
					this.getShortList();
					let ap = _.find(this.projectsShorts, p => p.isActive() && p.id != this.item.id);
					this.$emit('success', true);
					this.close();
					this.isLoading = false;

					if (ap && ap.id) {
						return this.$router.push({name: 'project_dashboard', params: {id: ap.id}});
					} else {
						return this.$router.push({name: 'add_project'});
					}
				})
				.catch (v => {
					this.$notify("error", this.$t('errors.projectRemove'), v)
					this.isLoading = false;
				});
		},
		archiveProject () {
			this.isLoading = true;

			this.archive({id: this.item.id})
				.then ( () => {
					this.$notify("success", this.$t('success.projectArchive'));
					this.$store.commit('project/archive', this.item);
					// this.$store.commit('project/opened', null);
					this.$emit('success', true);

					this.close();
					this.isLoading = false;
				})
				.catch (v => {
					this.$notify("error", this.$t('errors.projectArchive'), v)
					this.isLoading = false;
				});
		},
		close () { this.$refs.modal.hide(); },
	},
	watch : {
		item : {
			handler (nV, oV) {
				if ( nV != null && nV.id ) {
					this.$refs.modal.show();
				}
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.remove-project {
	max-width: 360px;
	padding: 20px;
	margin: 0 auto;

	@include respond-below(sm) {
		border-bottom-left-radius: var(--border-radius-rounded) !important;
		border-bottom-right-radius: var(--border-radius-rounded) !important;
		margin: auto 12px;
	}

	.modal-header {
		margin-bottom: 10px;
	}

	&__btn-cancel {
		margin-right: 16px;
	}
}
</style>
